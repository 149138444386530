import React from 'react';
import { bool, func, number, object, string } from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import uniq from 'lodash/uniq';

import { Form } from '../../../components';

import css from './WhenFilterForm.module.css';

const DEBOUNCE_WAIT_TIME = 400;

const months = [
  { value: 1, label: 'Jan' },
  { value: 2, label: 'Feb' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'Aug' },
  { value: 9, label: 'Sept' },
  { value: 10, label: 'Oct' },
  { value: 11, label: 'Nov' },
  { value: 12, label: 'Dec' },
];

const years = Array.from({ length: 3 }, (_, i) => ({
  value: new Date().getFullYear() + i,
  label: (new Date().getFullYear() + i).toString(),
}));

const isPast = (monthsValues, yearsValues) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // getMonth() is 0-indexed
  if (Array.isArray(monthsValues)) {
    const isPastForAllMonths = monthsValues.some(month => {
      if (yearsValues < currentYear) {
        return true;
      }
      if (yearsValues === currentYear && month < currentMonth) {
        return true;
      }
      return false;
    });

    return isPastForAllMonths;
  } else if (Array.isArray(yearsValues)) {
    const isPastForAllYears = yearsValues.some(year => {
      if (year < currentYear) {
        return true;
      }
      if (year === currentYear && monthsValues < currentMonth) {
        return true;
      }
      return false;
    });

    return isPastForAllYears;
  }
}

// Hidden input field
const FieldHidden = props => {
  const { name } = props;
  return (
    <Field id={name} name={name} type="hidden" className={css.unitTypeHidden}>
      {fieldRenderProps => <input {...fieldRenderProps?.input} />}
    </Field>
  );
};

const WhenFilterFormComponent = props => {
  const { liveEdit, onChange, onSubmit, onCancel, onClear, ...rest } = props;

  if (liveEdit && !onChange) {
    throw new Error('WhenFilterForm: if liveEdit is true you need to provide onChange function');
  }

  if (!liveEdit && !(onCancel && onClear && onSubmit)) {
    throw new Error(
      'WhenFilterForm: if liveEdit is false you need to provide onCancel, onClear, and onSubmit functions'
    );
  }

  const handleChange = debounce(
    formState => {
      if (formState.dirty) {
        const { month, year, ...restValues } = formState.values;
        onChange({
          month: month === '' ? rest.month : month,
          year: year === '' ? rest.year : year,
          ...restValues,
        });
      }
    },
    DEBOUNCE_WAIT_TIME,
    { leading: false, trailing: true }
  );

  const handleSubmit = values => {
    const { month, year, ...restValues } = values;
    return onSubmit({
      month: month === '' ? rest.month : month,
      year: year === '' ? rest.year : year,
      ...restValues,
    });
  };
  const formCallbacks = liveEdit
    ? { onSubmit: () => null }
    : { onSubmit: handleSubmit, onCancel, onClear };
  return (
    <FinalForm
      {...rest}
      {...formCallbacks}
      render={formRenderProps => {
        const {
          form,
          handleSubmit,
          id,
          showAsPopup,
          onClear,
          onCancel,
          isOpen,
          contentRef,
          style,
          intl,
          values,
          children,
        } = formRenderProps;
        const { month = [], year = [] } = values;
        const handleCancel = () => {
          // reset the final form to initialValues
          form.reset();
          onCancel();
        };

        const clear = intl.formatMessage({ id: 'WhenFilterForm.clear' });
        const cancel = intl.formatMessage({ id: 'WhenFilterForm.cancel' });
        const submit = intl.formatMessage({ id: 'WhenFilterForm.submit' });

        const classes = classNames(css.root, {
          [css.popup]: showAsPopup,
          [css.isOpenAsPopup]: showAsPopup && isOpen,
          [css.plain]: !showAsPopup,
          [css.isOpen]: !showAsPopup && isOpen,
        });
        return (
          <Form
            className={classes}
            onSubmit={handleSubmit}
            tabIndex="0"
            contentRef={contentRef}
            style={{ minWidth: '300px', ...style }}
          >
            <div className={css.contentWrapper}>
              <div className={css.inputsWrapper}>
                <div className={css.monthsWrapper}>
                  <div className={css.label}>
                    <FormattedMessage id="WhenFilterForm.month" />
                  </div>
                  <div className={css.months}>
                    {months.map((monthValue, index) => {
                      const isDateInPast = isPast(monthValue.value, year);
                      return (
                        <button
                          key={index}
                          className={classNames(css.unselected, {
                            [css.selected]: month?.includes(monthValue.value),
                            [css.disable]: isDateInPast,
                          })}
                          onClick={(e) => {
                            e.preventDefault();
                            const monthValues = uniq(month?.includes(monthValue.value) ? month?.filter(m => m !== monthValue.value) : month.concat(monthValue.value))
                            form.change('month', monthValues);
                          }}
                        >
                          {monthValue.label}
                        </button>
                      )
                    })}
                  </div>
                </div>

                <div className={css.yearsWrapper}>
                  <div className={css.label}>
                    <FormattedMessage id="WhenFilterForm.year" />
                  </div>
                  <div className={css.years}>
                    {years.map((yearValue, index) => {
                      const isDateInPast = isPast(month, yearValue.value);
                      return (
                        <button
                          key={index}
                          className={classNames(css.unselected, {
                            [css.selected]: year?.includes(yearValue.value),
                            [css.disable]: isDateInPast,
                          })}
                          onClick={(e) => {
                            e.preventDefault();
                            const yearValues = uniq(year?.includes(yearValue.value) ? year?.filter(y => y !== yearValue.value) : year.concat(yearValue.value))
                            form.change('year', yearValues);
                          }}
                        >
                          {yearValue.label}
                        </button>
                      )
                    })}
                  </div>
                </div>
                <FieldHidden name="month" />
                <FieldHidden name="year" />
              </div>
            </div>
            {liveEdit ? (
              <FormSpy onChange={handleChange} subscription={{ values: true, dirty: true }} />
            ) : (
              <div className={css.buttonsWrapper}>
                <button className={css.clearButton} type="button" onClick={onClear}>
                  {clear}
                </button>
                <button className={css.cancelButton} type="button" onClick={handleCancel}>
                  {cancel}
                </button>
                <button className={css.submitButton} type="submit">
                  {submit}
                </button>
              </div>
            )}
            {children}
          </Form>
        );
      }}
    />
  );
};

WhenFilterFormComponent.defaultProps = {
  liveEdit: false,
  showAsPopup: false,
  isOpen: false,
  contentRef: null,
  style: null,
  min: 0,
  step: 1,
  onCancel: null,
  onChange: null,
  onClear: null,
  onSubmit: null,
};

WhenFilterFormComponent.propTypes = {
  id: string.isRequired,
  liveEdit: bool,
  showAsPopup: bool,
  onCancel: func,
  onChange: func,
  onClear: func,
  onSubmit: func,
  isOpen: bool,
  contentRef: func,
  style: object,
  min: number.isRequired,
  max: number.isRequired,
  step: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

const WhenFilterForm = injectIntl(WhenFilterFormComponent);

export default WhenFilterForm;
