import React, { Component } from 'react';
import { arrayOf, func, node, number, shape, string, object } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { parse as parseUrl } from '../../../util/urlHelpers';
import { compact } from 'lodash';
import IconPlus from '../IconPlus/IconPlus';
import WhenFilterForm from '../WhenFilterForm/WhenFilterForm';

import css from './WhenFilterPlain.module.css';

const getWhenQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames)
    ? queryParamNames[0]
    : typeof queryParamNames === 'object'
      ? queryParamNames
      : 'when';
};

const parse = when => {
  const { month, year } = !!when ? parseUrl(when) : {};
  const months = typeof month === 'number' ? [month] : compact(month?.split(','))?.map(m => parseInt(m)) || []
  const years = typeof year === 'number' ? [year] : compact(year?.split(','))?.map(m => parseInt(m)) || []
  return !!months?.length > 0 && years?.length > 0 ? { month: months, year: years } :
    !!months?.length > 0 ? { month: months } :
      !!years?.length > 0 ? { year: years } : null;
};
const format = (when, queryParamName) => {
  const { month, year } = when || {};
  const value = !!month && !!year ? `month=${month}&year=${year}` :
    !!month ? `month=${month}` : !!year ? `year=${year}` : null;
  return { [queryParamName]: value };
};

class WhenFilterPlainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: true };

    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  handleChange(values) {
    const { onSubmit, queryParamNames } = this.props;
    const whenQueryParamName = getWhenQueryParamName(queryParamNames);
    onSubmit(format(values, whenQueryParamName));
  }

  handleClear() {
    const { onSubmit, queryParamNames } = this.props;
    const whenQueryParamName = getWhenQueryParamName(queryParamNames);
    onSubmit(format(null, whenQueryParamName));
  }

  toggleIsOpen() {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      label,
      queryParamNames,
      initialValues,
      intl,
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    const whenQueryParam = getWhenQueryParamName(queryParamNames);
    const initialWhen = initialValues ? parse(initialValues[whenQueryParam]) : {};
    const { month, year } = initialWhen || {};
    const hasValue = value => value != null;
    const hasInitialValues = initialValues && hasValue(month) || hasValue(year);
    const monthStr = month?.join(',');
    const yearStr = year?.join(',');

    const labelSelection = hasInitialValues && hasValue(month) && hasValue(year)
      ? intl.formatMessage(
        { id: 'WhenFilter.labelSelectedButton' },
        {
          month: monthStr,
          year: yearStr
        }
      )
      : hasInitialValues && hasValue(month) ?
        intl.formatMessage(
          { id: 'WhenFilter.labelMonthSelectedButton' },
          {
            month: monthStr,
          }
        ) : hasInitialValues && hasValue(year) ?
          intl.formatMessage(
            { id: 'WhenFilter.labelYearSelectedButton' },
            {
              year: yearStr
            }
          ) : null
    return (
      <div className={classes}>
        <div className={css.filterHeader}>
          <button type="button" className={css.labelButton} onClick={this.toggleIsOpen}>
            <span className={css.labelButtonContent}>
              <span className={css.labelWrapper}>
                <span className={css.label}>
                  {label}
                  {labelSelection ? (
                    <>
                      <span>{': '}</span>
                      <span className={css.labelSelected}>{labelSelection}</span>
                    </>
                  ) : null}
                </span>
              </span>
              <span className={css.openSign}>
                <IconPlus isOpen={this.state.isOpen} isSelected={hasInitialValues} />
              </span>
            </span>
          </button>
        </div>
        <div className={css.formWrapper}>
          <WhenFilterForm
            id={id}
            initialValues={hasInitialValues ? initialWhen : { month: month, year: year }}
            onChange={this.handleChange}
            intl={intl}
            contentRef={node => {
              this.filterContent = node;
            }}
            liveEdit
            isOpen={this.state.isOpen}
            isInSideBar
            style={{ minWidth: '160px' }}
          >
            <button className={css.clearButton} onClick={this.handleClear}>
              <FormattedMessage id={'WhenFilterForm.clear'} />
            </button>
          </WhenFilterForm>
        </div>
      </div>
    );
  }
}

WhenFilterPlainComponent.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  step: number,
};

WhenFilterPlainComponent.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  queryParamNames: arrayOf(string).isRequired,
  onSubmit: func.isRequired,
  initialValues: shape({
    when: object,
  }),
  month: number.isRequired,
  year: number.isRequired,

  // form injectIntl
  intl: intlShape.isRequired,
};

const WhenFilterPlain = injectIntl(WhenFilterPlainComponent);

export default WhenFilterPlain;
